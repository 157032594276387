import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useContext } from 'react';
import { authStoreContext } from '../../stores/AuthStore';
import { errorsStoreContext } from '../../stores/ErrorsStore';
import { uiSoreContext } from '../../stores/UiStore';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import './layout.scss';

const Layout = ({ children }) => {
	const { authenticated } = useContext(authStoreContext);
	const { menuCollapsed } = useContext(uiSoreContext);
	const { errors } = useContext(errorsStoreContext);

	return (
		<>
			{!authenticated && <Header />}
			<main
				className={clsx(
					'grow flex flex-col',
					{ 'sm:pl-44': authenticated },
					{ '!pl-16': menuCollapsed && authenticated },
				)}
			>
				{authenticated && <Sidebar />}
				{children}
				<div className="flex flex-col fixed right-4 bottom-4 gap-1 z-top">
					{errors.map(({ id, message }) => (
						<div key={id} className="w-fit p-3 bg-red-600 rounded-sm break-words text-white">
							{message}
						</div>
					))}
				</div>
				{!authenticated && <Footer />}
			</main>
		</>
	);
};

export default observer(Layout);
