import { DoubleLeftOutlined, DoubleRightOutlined, InfoCircleOutlined, LogoutOutlined } from '@ant-design/icons';
import { Menu, Modal } from 'antd';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useMemo } from 'react';
import { authStoreContext } from '../../stores/AuthStore';
import { uiSoreContext } from '../../stores/UiStore';
import { userStoreContext } from '../../stores/UserStore';
import menuItems from './menuItems';
import homeImage from '../../images/home.png';
import './style.scss';

interface Props {}

const DEFAULT_KEY = 'home';

const Sidebar = (props: Props) => {
	const { setMenuCollapsed, menuCollapsed: collapsed } = useContext(uiSoreContext);
	const { logout } = useContext(authStoreContext);
	const { user } = useContext(userStoreContext);

	const items = useMemo(() => {
		return menuItems.filter(({ roles }) => roles.includes(user!.role));
	}, [user]);

	const handleOpenHelp = useCallback(() => {
		Modal.info({
			centered: true,
			title: 'Get help',
			content: (
				<div>
					{/* TODO: replace content */}
					<span>If you have some questions you can write email to </span>
					<b>
						<a href="mailto:sales@theta-wave.io">sales@theta-wave.io</a>
					</b>
				</div>
			),
		});
	}, []);

	return (
		<div className={clsx({ 'w-min': collapsed }, 'bg-white sidebar w-screen md:w-auto')}>
			<Menu inlineCollapsed={collapsed} defaultSelectedKeys={[DEFAULT_KEY]} mode="inline">
				<Menu.Item
					key={DEFAULT_KEY}
					icon={collapsed && <img src={homeImage} alt="home" className="w-5 !after:hidden" />}
				>
					<Link to={'/home'}>
						<span className="font-bold text-xl">ThetaWave</span>
					</Link>
				</Menu.Item>
				{items.map(({ to, name, icon }, index) => (
					<Menu.Item key={index} icon={icon}>
						<Link to={to}>{name}</Link>
					</Menu.Item>
				))}
			</Menu>
			<div style={{ flexGrow: 1 }}></div>
			<Menu inlineCollapsed={collapsed} selectable={false} mode="inline">
				<Menu.Item onClick={handleOpenHelp} key="2" icon={<InfoCircleOutlined />}>
					Help
				</Menu.Item>
				<Menu.Item onClick={logout} key="3" icon={<LogoutOutlined />}>
					Exit
				</Menu.Item>
			</Menu>

			<button
				className="border-none p-6 flex justify-center items-center hover:bg-gray-200 cursor-pointer bg-gray-300 transition-all"
				onClick={() => setMenuCollapsed(!collapsed)}
			>
				{collapsed ? (
					<DoubleRightOutlined />
				) : (
					<>
						<DoubleLeftOutlined className="mr-4 text-base" />{' '}
						<span className="inline-block leading-4">Collapse sidebar</span>
					</>
				)}
			</button>
		</div>
	);
};

export default observer(Sidebar);
